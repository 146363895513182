<template>
   <div class="job-loader">
     <Loading :is_loading="loading" :message="message"></Loading>
     <div class="job-loader-subtitle" v-if="subtitle">
       {{subtitle}}
     </div>
   </div>
</template>

<script>

import DashboardService from '../services/dashboard.service';
import Loading from "../components/Loading";

export default {
  name: 'JobLoader',
  components: {
    Loading
  },
  data() {
    return {
      loading: true,
      message: 'Loading Job Status...',
      reportId: 0,
      subtitle: '',
      jobId: null,
      interval: null,
      statusLookup: {
        'unknown': "Report Status: Unknown",
        'Failed': "Report Status: Failed",
        'Queued': "Report Status: Queued...",
        'InProgress': "Report Status: In Progress...",
        'Complete': "Report Status: DONE!"
      },
      statusSubtitle: {
        'unknown': "Something has gone wrong.",
        'Failed': "The report failed to complete successfully! You'll be redirectly to the homepage.",
        'Queued': "Your report is in the queue to be processed. Please wait.",
        'InProgress': "We're working on your report right now! Please wait.",
        'Complete': "Your report is complete! We'll take you to the report now."
      }
    };
  },
  async mounted() {
    if (this.$route.params.hasOwnProperty('job_id')) {
      this.jobId = this.$route.params.job_id  // TODO - This is actually the report id?
    } else {
      // TODO - Remove this when testing is done, it should redirect to the homepage.
      console.log('No Job ID Specified!')
    }

    let newStatus = await  DashboardService.reportStatus(this.jobId)
    this.updateStatus(newStatus.data.status)
    this.reportId = newStatus.report_id

    this.interval = setInterval(() => {
      DashboardService.reportStatus(this.jobId).then((result) => {

        this.updateStatus(result.data.status)
      })
    }, 5000)
  },
  methods: {
    updateStatus(newStatus) {
      if (this.statusLookup.hasOwnProperty(newStatus)) {
        this.message = this.statusLookup[newStatus]
        this.subtitle = this.statusSubtitle[newStatus]
      } else {
        this.message = this.statusLookup['unknown']
        this.subtitle = this.statusSubtitle['unknown']
      }

      if (newStatus === 'Complete') {
        clearInterval(this.interval)
        setTimeout(()=> {
          this.$router.push('/home/reports/' + this.jobId)
        }, 1000)
      } else if (newStatus === 'Failed') {
        clearInterval(this.interval)
        setTimeout(()=> {
          this.$router.push('/home/')
        }, 5000)
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
};
</script>

<style scoped>
.job-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 40px;
  padding-top: 30%;
}
</style>
